// import { Bold, Code, Italic, Strikethrough, Subscript, Superscript, Underline } from '@ckeditor/ckeditor5-basic-styles';
// import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Alignment } from "@ckeditor/ckeditor5-alignment";
import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
import { Font } from "@ckeditor/ckeditor5-font";
import { Heading } from "@ckeditor/ckeditor5-heading";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
} from "@ckeditor/ckeditor5-basic-styles";

import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import {
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageResizeButtons,
  ImageResizeEditing,
  ImageResizeHandles,
} from "@ckeditor/ckeditor5-image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
// import { FindAndReplace } from '@ckeditor/ckeditor5-find-and-replace';
import { Highlight } from "@ckeditor/ckeditor5-highlight";
import { Indent } from "@ckeditor/ckeditor5-indent";
import { Link, LinkImage, AutoLink } from "@ckeditor/ckeditor5-link";
import { List } from "@ckeditor/ckeditor5-list";
import { MediaEmbed } from "@ckeditor/ckeditor5-media-embed";
import {
  Table,
  TableToolbar,
  TableCaption,
  TableColumnResize,
  TableCellProperties,
  TableProperties,
} from "@ckeditor/ckeditor5-table";
// import { TextTransformation } from '@ckeditor/ckeditor5-typing';
import { SourceEditing } from "@ckeditor/ckeditor5-source-editing";
import { GeneralHtmlSupport } from "@ckeditor/ckeditor5-html-support";
import SimpleUploadAdapter from "./Plugins/SimpleUpload";
import mergeDeep from "../../../util/deepMerge";
// import FullScreen from "./Plugins/fullscreen";
import { PasteFromOffice } from "@ckeditor/ckeditor5-paste-from-office";
import { HorizontalLine } from "@ckeditor/ckeditor5-horizontal-line";

// import { Template } from '@ckeditor/ckeditor5-template';

export const editorConfiguration = (props) => {
  return mergeDeep(
    {
      initialData: "",
      language: "zh-cn",
      plugins: [
        GeneralHtmlSupport,
        Strikethrough,
        Subscript,
        Superscript,
        Underline,
        // FindAndReplace,
        Highlight,
        Essentials,
        Font,
        Underline,
        Bold,
        Italic,
        Autoformat,
        Paragraph,
        Alignment,
        BlockQuote,
        Heading,
        Image,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        ImageResizeEditing,
        ImageResizeHandles,
        ImageResizeButtons,
        Indent,
        // Italic,
        Link,
        // LinkImage,
        // AutoLink,
        List,
        MediaEmbed,
        Paragraph,
        Table,
        TableToolbar,
        TableCaption,
        TableProperties,
        TableColumnResize,
        TableCellProperties,
        // TextTransformation,
        // Template,
        SourceEditing,
        SimpleUploadAdapter,
        // FullScreen,
        PasteFromOffice,
        HorizontalLine,
      ],
      toolbar: {
        items: [
          "heading",
          "|",
          {
            label: "字体样式",
            icon: "text",
            items: [
              "bold",
              "italic",
              "fontSize",
              "fontFamily",
              "fontColor",
              "fontBackgroundColor",
              "highlight",
              "|",
              "horizontalLine",
              "strikethrough",
              "subscript",
              "superscript",
            ],
          },
          // 'bold',
          // 'italic',
          "alignment",
          "link",
          "bulletedList",
          "numberedList",
          // 'fontSize',
          // "fontColor",
          // "fontBackgroundColor",
          "|",
          "indent",
          "outdent",
          "|",
          "imageUpload",
          "blockQuote",
          "insertTable",
          "|",
          "mediaEmbed",
          "undo",
          "redo",
          // 'htmlEmbed',
          "sourceEditing",
          // 'fullScreen'
          // 'link',
          // 'alignment',
          // 'findAndReplace',
          // // {
          // // 	label: 'Formatting',
          // // 	icon: 'text',
          // // 	items: ['fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'strikethrough', 'subscript', 'superscript', 'code', 'horizontalLine', '|', 'removeFormat']
          // // },
          // {
          // 	label: 'Insert',
          // 	icon: 'plus',
          // 	items: ['highlight', 'blockQuote', 'mediaEmbed', 'codeBlock',
          // 		'insertTable', 'imageUpload', 'htmlEmbed']
          // },
          // '|',
          // 'outdent',
          // 'indent',
          // '|',
          // 'sourceEditing'
        ],
      },
      fontFamily: {
        supportAllValues: true,
      },
      fontSize: {
        options: [10, 12, 14, "default", 18, 20, 22],
        supportAllValues: true,
      },
      htmlSupport: {
        allow: [
          {
            // name: /^(section|div|article|svg|svgDOMElement|canvas|footer|main|strong)$/,
            // attributes: true,
            // classes: true,
            // styles: true
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true,
          },
        ],
        disallow: [
          {
            name: /^script$/,
          },
          {
            name: /[\s\S]+/, // For every HTML feature,
            attributes: {
              key: /^on.*$/, // disable 'on*' attributes, like 'onClick', 'onError' etc.
            },
          },
        ],
        allowEmpty: ["i", "span"],
      },
      image: {
        styles: ["alignCenter", "alignLeft", "alignRight"],
        resizeOptions: [
          {
            name: "resizeImage:original",
            label: "Original",
            value: null,
          },
          {
            name: "resizeImage:50",
            label: "50%",
            value: "50",
          },
          {
            name: "resizeImage:75",
            label: "75%",
            value: "75",
          },
        ],
        toolbar: [
          "imageTextAlternative",
          "toggleImageCaption",
          "|",
          "imageStyle:full",
          "imageStyle:inline",
          "imageStyle:block",
          "imageStyle:side",
          "imageStyle:wrapText",
          "imageStyle:breakText",
          //   "|",
          //   "resizeImage:50",
          //   "resizeImage:75",
          //   "resizeImage:original",
          //   "resizeImage",
        ],
      },
      // list: {
      // 	properties: {
      // 		styles: true,
      // 		startIndex: true,
      // 		reversed: true
      // 	}
      // },
      // link: {
      // 	decorators: {
      // 		addTargetToExternalLinks: true,
      // 		defaultProtocol: 'https://',
      // 		toggleDownloadable: {
      // 			mode: 'manual',
      // 			label: 'Downloadable',
      // 			attributes: {
      // 				download: 'file'
      // 			}
      // 		}
      // 	}
      // },
      alignment: {
        options: ["left", "right", "center"],
      },
      table: {
        defaultHeadings: { rows: 1, columns: 1 },
        contentToolbar: [
          "toggleTableCaption",
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableProperties",
          "tableCellProperties",
        ],
      },
      simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: "",

        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: true,

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
          // 'X-CSRF-TOKEN': 'CSRF-Token',
          // Authorization: 'Bearer <JSON Web Token>'
        },
        onSuccess: (response) => {
          return response.data;
        },
      },
      mediaEmbed: {
        extraProviders: {
          name: "extraProviders",
          url: /.+/,
          html: (match) => {
            const _url = match[0];

            let _isMp3 = /\\*(.mp3)$/.test(_url);

            if (_isMp3)
              return `<div style="position: relative;height:100px;">
            <iframe src="${_url}" 
            style="width:100%;height:60px" frameborder="0" 
            webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>`;

            return `<div style="position: relative;">
            <iframe src="${_url}" 
            style="width:100%;height:420px" frameborder="0" 
            webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>`;
          },
        },
      },
    },
    props || {}
  );
};
