import React from "react";
import { IFormItemProps } from "../../../../types";
import "./index.scss";
import classNames from "classnames";
import RenderFormItems from "../../formItem";

const RenderFormViewSection = (props: IFormItemProps) => {
	const { title, children = [], ...restprops } = props;
	return (
		<section className={classNames("form-section split-section")}>
			<div className="form-section-head">{title}</div>
			<div className="form-section-body">
				<RenderFormItems formItems={children ?? []} {...restprops}></RenderFormItems>
			</div>
			<div className="form-section-footer"></div>
		</section>
	);
};

export default RenderFormViewSection;