import dayjs from "dayjs";

const uploadNormFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};

//make form get value from diff prop names
export const getFormItemValueProps = (type: string) => {
  const ItemType: string = type ? type.toLowerCase() : "input";

  switch (ItemType) {
    case "switch":
      return { valuePropName: "checked" };
    case "upload":
      return { valuePropName: "fileList", getValueFromEvent: uploadNormFile };
    case "checkbox":
      return { valuePropName: "checked" };
    case "tree":
      return {
        valuePropName: "data",
        getValueFromEvent: (evt, nodes) => {
          return evt;
        },
      };
    case "date":
      return {
        getValueProps: (value) => ({ value: value ? dayjs(value) : undefined }),
      };
    case "edittable":
      return {
        valuePropName: "data",
      };
    default:
      return { valuePropName: "value" };
  }
};
