import React, { useEffect, useState, useImperativeHandle, useRef } from "react";
import { FormComponent } from "../common";
import { Button, Drawer, FormInstance, Space } from "antd";
import { IDrawerFormProps, IFormItemProps, IModalFormRef } from "../../types";


const DrawerForm: React.FC<IDrawerFormProps> = React.forwardRef(
  (props: IDrawerFormProps, ref: React.Ref<IModalFormRef>): JSX.Element => {
    const [visible, setVisible] = useState<boolean>(false);
    const formRef = useRef<FormInstance>({} as FormInstance);

    const {
      formItems,
      onOk = () => {},
      onCancel = () => {},
      resetOnClose = true,
      ...restProps
    } = props;

    useImperativeHandle<IModalFormRef, IModalFormRef>(
      ref,
      () => ({
        setVisible,
        ...formRef.current,
      }),
      []
    );

    const onClose = () => {
      setVisible(false);
      formRef.current.resetFields();
      onCancel();
    };

    const onSave = async () => {
      const validate = await formRef.current.validateFields();

      onOk(formRef.current.getFieldsValue());
      setVisible(false);

      if (resetOnClose) {
        formRef.current.resetFields();
      }
    };

    useEffect(() => {
      if (props.data) {
        formRef.current.setFieldsValue(props.data);
      }
    }, [props.data]);

    return (
      <Drawer
        open={visible}
        width={680}
        maskClosable={false}
        destroyOnClose={true}
        onClose={onClose}
        title={props.title ?? " "}
        closable={true}
        forceRender={true}
        extra={
          <Space>
            <Button onClick={onClose}>取消</Button>
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
          </Space>
        }
      >
        <FormComponent
          ref={formRef}
          formItems={formItems}
          preserve={false}
          {...restProps}
        />
      </Drawer>
    );
  }
);

export default DrawerForm;
