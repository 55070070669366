import React, { useState, useEffect } from "react";
import { Upload, Button, Form } from "antd";
import "./index.scss";
import ImgCrop from "antd-img-crop";

let _uploading = false;
let _uploadFiles = [];
let _count = 0;
const CustomUpload = (props) => {
  let {
    text = "上传文件",
    multiple,
    icon = <>+</>,
    fileList,
    listType = "text",
    maxCount,
    imgCrop = false,
  } = props;

  if (multiple && !maxCount) {
    maxCount = 5;
  } else if (!maxCount) maxCount = 1;

  const formInstance = Form.useFormInstance();

  const isDisabled =
    !fileList || (fileList && fileList.length === 0) ? false : true;
  const [onUploading, setUploadStatus] = useState(false);
  const [uploadFiles, setUploadFiles] = useState(props.fileList || []);

  _uploading = onUploading;

  const uploadButton = (
    <>
      {onUploading ? <>上传中...</> : <>+</>}
      {props.children ? (
        props.children
      ) : (
        <div className="ant-upload-text">{text ? text : "上传图片 "}</div>
      )}
    </>
  );

  const uploadButtonList = (
    <>
      {onUploading ? (
        <>上传中...</>
      ) : props.children ? (
        props.children
      ) : (
        <Button disabled={isDisabled}>{text ? text : "上传图片"}</Button>
      )}
    </>
  );

  useEffect(() => {
    if (!!fileList) {
      setUploadFiles(fileList.filter((item) => !item.status));
    }
  }, [fileList]);

  const _beforeUpload = (data) => {
    if (!_uploading) {
      props.onUploadChange && props.onUploadChange(null);
      setUploadStatus(true);
    }

    _count = _count + 1;

    const _beforeUploadStatus = !props.beforeUpload || props.beforeUpload(data);

    if (!_beforeUploadStatus && _uploading) {
      setUploadStatus(false);
    }

    return _beforeUploadStatus;
  };

  const _onSuccess = (res) => {
    console.log(res, "onsuccess");
    if (_uploading) {
      setUploadStatus(false);
    }

    const _fileList = !!props.onBeforeSuccess
      ? props.onBeforeSuccess(res)
      : [res];

    _uploadFiles = _uploadFiles.concat(_fileList);

    //当全部上传完成之后清空当前上传的数组
    const uploadedFiles = [...uploadFiles, ..._uploadFiles];
    props.onChange && props.onChange(uploadedFiles.slice(0, maxCount));
    setUploadFiles(uploadedFiles.slice(0, maxCount));

    _uploadFiles.splice(0, _uploadFiles.length);
    _count = 0;

    if (formInstance) {
      formInstance.setFieldValue(
        props.formItemName,
        uploadedFiles.slice(0, maxCount)
      );
    }

    props.onSuccess && props.onSuccess(res);
  };

  const _props = {
    ...props,
    onSuccess: _onSuccess,
    beforeUpload: _beforeUpload,
    fileList: uploadFiles,
  };

  const RenderContent = () => {
    if (listType === "picture") {
      return (
        <Upload listType="picture" {..._props}>
          {
            uploadFiles.length >= maxCount ? null : uploadButtonList // 单张图片
          }
        </Upload>
      );
    } else if (listType === "text") {
      return (
        <Upload {..._props}>
          <Button icon={icon}>{text}</Button>
        </Upload>
      );
    } else {
      return (
        <Upload className="upload-card" listType="picture-card" {..._props}>
          {
            uploadFiles.length >= maxCount ? null : uploadButton //  多张图片/单张图片
          }
        </Upload>
      );
    }
  };

  if (imgCrop) {
    let _cropProps = Object.prototype.toString.call("[object Object]")
      ? imgCrop
      : { rotationSlider: true };
    return <ImgCrop {..._cropProps}>{RenderContent()}</ImgCrop>;
  }

  return <>{RenderContent()}</>;
};

export default CustomUpload;
