import { Button, Form, Popconfirm, Space } from "antd";
import React, { useCallback } from "react";
import RenderInlineFormItem from "../FormItemInline";
import { IFormItemProps } from "../../../../types";

const RenderFormListItem = (props: IFormItemProps) => {
  const { children,
    min,
    max } = props;
  if (children?.length === 0) {
    return <></>;
  }

  const {
    hidden = false,
    name = "list",
    rules = [],
    // children = [],
    label = "",
    noStyle = false,
    disabled = false,
    editable = true,
    removable = true,
    ...restProps
  } = props.formItemProps || {};

  const form = Form.useFormInstance();

  return (
    <Form.Item noStyle={noStyle} {...restProps}>
      <Form.List name={name} {...restProps}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(
                ({ key, name: fieldName, ...restField }, itemIndex) => (
                  <Space
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                    key={key}
                  >
                    {children.map((child: IFormItemProps, index: number) => {
                      let _child = Object.assign({}, child);

                      const _name = _child["formItemProps"]["name"] as
                        | string
                        | number;

                      _child.formItemProps = {
                        ...restField,
                        ...child.formItemProps,
                        name: [fieldName, _name],
                        shouldUpdate: () => !!props?.isDisabled,
                        label: Object.prototype.toString.call(_child?.formItemProps?.label) === "[object Function]" ? _child?.formItemProps?.label?.(itemIndex) : _child?.formItemProps?.label
                      };

                      _child.formControlProps = {
                        ...(child.formControlProps || {}),
                        disabled:
                          props?.isDisabled?.(
                            child,
                            index,
                            itemIndex,
                            form.getFieldValue(name)?.[itemIndex]?.[_name],
                            form.getFieldValue(name)?.[itemIndex]
                          ) ?? false,
                      };

                      return (
                        <RenderInlineFormItem
                          key={`list-form-item-${index}`}
                          {..._child}
                          index={index}
                        />
                      );
                    })}
                    {((!removable &&
                      typeof removable === "function" &&
                      !removable?.({
                        ...props,
                        type: "operator",
                        itemIndex,
                        data: form.getFieldValue(name)?.[
                          itemIndex
                        ],
                      })) ||
                      removable) && (typeof (min) === "undefined" || fields.length > min) && (
                        <Popconfirm
                          title="确定移除该条记录？"
                          onConfirm={() => remove(fieldName)}
                        >
                          <Button
                            type="link"
                            // className="danger"
                            key={`list-item-remove-${props.index}`}
                            danger
                            icon={<>&times;</>}
                          >
                            {props.removeComponent ? (
                              <>{props.removeComponent}</>
                            ) : (
                              <>移除 </>
                            )}
                          </Button>
                        </Popconfirm>
                      )}
                  </Space>
                )
              )}

              {!disabled && editable && (typeof (max) === "undefined" || max > fields.length) && (
                <Button
                  icon={<>+</>}
                  type="dashed"
                  onClick={() => add()}
                >
                  添加
                </Button>
              )}
            </>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};

export default RenderFormListItem;
