import { Col, Form, Row } from "antd";
import React from "react";
import { IFormItemProps } from "../../../../types";
import RenderFormItem from "../FormItemNormal";
import "./index.scss"

const RenderGroupItem = (props: IFormItemProps) => {
  let { hidden, label, shouldUpdate, ...restProps } = props.formItemProps;
  if (!props.children || props.children.length === 0) {
    return <></>;
  }

  let { formItemProps } = props;
  const form = Form.useFormInstance();


  let _hidden = false;

  if (typeof hidden === "function") {
    shouldUpdate = typeof shouldUpdate === "undefined" ? true : shouldUpdate;
  } else {
    _hidden = hidden;
  }

  const isControlHidden = () => {
    let _isHide = hidden;

    if (typeof hidden === "function") _isHide = hidden?.(form.getFieldsValue());

    return _isHide;
  };

  //ͨ��shouldUpdate���Կ������������
  if (!!shouldUpdate) {
    return (
      <Form.Item
        noStyle={true}
        shouldUpdate={(prev, current, info) =>
          typeof shouldUpdate === "function"
            ? shouldUpdate(prev, current, info)
            : shouldUpdate
        }
      >
        {(formProps) => {
          return (
            !isControlHidden() && (
              <Form.Item
                hidden={typeof hidden === "boolean" ? hidden : false}
                label={Object.prototype.toString.call(label) === "[object Function]" ? label() : label}
                noStyle={!label}
                {...restProps}
              >
                <Row className="form-item-group">
                  {props.children.map((child: IFormItemProps, index: number) => {
                    const { formItemProps, formControlProps, ...restProps } = child;

                    return (
                      <Col className="form-item-group-item" key={`inline-form-item-${index}`} xxl={{ span: Math.floor(24 / props.children.length) }} xs={{ span: 24 }} >
                        <RenderFormItem
                          {...restProps}
                          formItemProps={{
                            noStyle: true,
                            ...formItemProps
                          }}
                          formControlProps={formControlProps}
                          index={index}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Form.Item>
            )
          );
        }}
      </Form.Item>
    );
  }


  return (
    <Form.Item
      hidden={typeof hidden === "boolean" ? hidden : false}
      label={Object.prototype.toString.call(label) === "[object Function]" ? label() : label}
      noStyle={!label}
      {...restProps}
    >
      <Row>
        {props.children.map((child: IFormItemProps, index: number) => {
          const { formItemProps, formControlProps, ...restProps } = child;

          return (
            <Col key={`inline-form-item-${index}`} md={{ span: Math.floor(24 / props.children.length) }} xs={{ span: 24 }}>
              <RenderFormItem
                {...restProps}
                formItemProps={{
                  noStyle: true,
                  ...formItemProps
                }}
                formControlProps={formControlProps}
                index={index}
              />
            </Col>
          );
        })}
      </Row>
    </Form.Item>
  );
};

export default RenderGroupItem;
