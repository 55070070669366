
import React, { useEffect, useRef } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import { editorConfiguration } from "./config";
import "./index.scss";
// import zhCN from "@ckeditor/ckeditor5-core/lang/"

const FormEditor = (props) => {
  const editorRef = useRef()

  //@ts-ignore
  return <>
    <CKEditor
      ref={editorRef}
      editor={ClassicEditor}
      config={editorConfiguration(props || {})}
      data={props.value || ""}
      onReady={(editor) => {
        // console.log(args, '---')
      }}
      onChange={(evt, editor) => {
        props?.onChange?.(editor.getData())
      }}
    />
  </>
}

export default FormEditor;