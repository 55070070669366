import React from "react";
import { IFormItemProps } from "../../../../types";
import RenderFormItem from "../FormItemNormal";
import { getFormItemValueProps } from "../../utils";

const RenderInlineFormItem = (props: IFormItemProps) => {
  const { label, style, ...restProps } = props.formItemProps ?? {};

  return (
    <RenderFormItem
      {...props}
      formItemProps={{
        ...props.formItemProps,
        ...{
          ...getFormItemValueProps(props.type),
          style,
          ...restProps,
        },
      }}
    />
  );
};

export default RenderInlineFormItem;
