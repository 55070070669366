import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository';
import { logWarning } from '@ckeditor/ckeditor5-utils';

import Adapter from './adapter';

export default class SimpleUpload extends Plugin {

	static get requires() {
		return [FileRepository];
	}

	static get pluginName() {
		return 'SimpleUpload';
	}

	init() {
		const options = this.editor.config.get('simpleUpload');
		if (!options) {
			return;
		}
		if (!options.uploadUrl) {
			/**
			 * The {@link module:upload/uploadconfig~SimpleUploadConfig#uploadUrl `config.simpleUpload.uploadUrl`}
			 * configuration required by the {@link module:upload/adapters/simpleuploadadapter~SimpleUploadAdapter `SimpleUploadAdapter`}
			 * is missing. Make sure the correct URL is specified for the image upload to work properly.
			 *
			 * @error simple-upload-adapter-missing-uploadurl
			 */
			logWarning('simple-upload-adapter-missing-uploadurl');
			return;
		}
		this.editor.plugins.get(FileRepository).createUploadAdapter = loader => {
			return new Adapter(loader, options);
		};
	}
}