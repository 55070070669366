import { Form } from "antd";
import React from "react";
import { IFormItemProps } from "../../../../types";
import { getFormItemValueProps } from "../../utils";
import { getFormItem } from "..";

const RenderFormItem = (props: IFormItemProps) => {
  let { formItemProps } = props;
  const form = Form.useFormInstance();

  let { hidden = false, shouldUpdate, style, ...restProps } =
    formItemProps || {};

  let _hidden = false;

  if (
    typeof hidden === "function" ||
    typeof props.formControlProps?.disabled === "function"
  ) {
    shouldUpdate = typeof shouldUpdate === "undefined" ? true : shouldUpdate;
  } else {
    _hidden = hidden;
  }

  const isControlHidden = () => {
    let _isHide = hidden;

    if (typeof hidden === "function") _isHide = hidden?.(form.getFieldsValue());

    return _isHide;
  };

  //通过shouldUpdate属性控制组件的属性
  if (!!shouldUpdate) {
    return (
      <Form.Item
        noStyle={true}
        shouldUpdate={(prev, current, info) =>
          typeof shouldUpdate === "function"
            ? shouldUpdate(prev, current, info)
            : shouldUpdate
        }
      >
        {(formProps) => {
          if (!isControlHidden())
            return (
              <Form.Item
                {...getFormItemValueProps(props.type)}
                {...restProps}
                style={style}
              >
                {getFormItem(props, form)}
              </Form.Item>
            );

          return <></>;
        }}
      </Form.Item>
    );
  }

  return (
    <Form.Item
      hidden={_hidden}
      {...getFormItemValueProps(props.type)}
      style={style}
      {...(restProps || {})}
    >
      {getFormItem(props, form)}
    </Form.Item>
  );
};

export default RenderFormItem;
