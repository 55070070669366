import React from "react";
import { Form, Row, Col, Button, Space, Popconfirm, Divider } from "antd";

import { IFormItemProps, IFilterFormProps, IFormProps } from "../../types";

import RenderFormItem from "./controls/FormItemNormal";
import RenderInlineFormItem from "./controls/FormItemInline";
import RenderGroupItem from "./controls/FormItemGroup";
import RenderFormTableListItem from "./controls/FormItemTableList";
import RenderFormListItem from "./controls/FormItemList";
import RenderFormViewSection from "./controls/FormViewSection";

const RenderDivider = () => {
  return (
    <Form.Item key={Symbol("divider").toString()} noStyle>
      <Divider dashed></Divider>
    </Form.Item>
  );
};

const RenderSection = (props: IFormItemProps) => {
  return (
    <section>
      <div className="section-head"></div>
      <div className="section-body"></div>
      <div className="section-footer"></div>
    </section>
  );
};

export const RenderFormItems: React.FC<any> = (
  props: IFormProps
): JSX.Element => {
  const { formItems, layout } = props;

  return (
    <>
      {formItems.map((item: IFormItemProps, index: number) => {
        if (Object.keys(item).length === 0) return;

        switch (item.type) {
          case "divider":
            return (
              <RenderDivider
                key={Symbol("divider" + Math.random()).toString()}
              />
            );
          case "section":
            return (
              <RenderFormViewSection key={Symbol(`section_${index ?? 0}`).toString()} index={index} {...item} />
            );
          case "group":
            return (
              <RenderGroupItem
                key={Symbol("group").toString()}
                {...item}
                index={index}
              ></RenderGroupItem>
            );
          case "list":
            return (
              <RenderFormListItem
                key={Symbol("list").toString()}
                {...item}
                index={index}
              ></RenderFormListItem>
            );
          case "tablelist":
            return (
              <RenderFormTableListItem
                key={Symbol("tablelist").toString()}
                {...item}
                index={index}
              ></RenderFormTableListItem>
            );
          default:
            if (item.children) {
              return (
                <Row
                  gutter={24}
                  key={`${Symbol(item.id ?? "form-item-" + index).toString()}`}
                  style={{ margin: "0px" }}
                >
                  {(item.children || []).map(
                    (child: IFormItemProps, index: number) => {
                      const { rules, ...restProps } = Object.create(
                        child.formItemProps || {}
                      );
                      const _perSpace = Math.ceil(
                        24 / (props.itemsPerLine || item.children.length)
                      );

                      const _layout = {
                        span: _perSpace,
                      };

                      child.formItemProps = {
                        ...(child.formItemProps || {}),
                      };
                      if (child.children) {
                        return (
                          <Col
                            xs={24}
                            sm={_perSpace}
                            key={`form-row-item-${index}`}
                            style={{ padding: "0px" }}
                            {..._layout}
                            {...restProps}
                            {...child.rowLayout}
                          >
                            <RenderFormItems
                              formItems={child.children}
                              key={`${Symbol(
                                child.id ?? "form-item-" + index
                              ).toString()}`}
                            />
                          </Col>
                        );
                      }

                      const {
                        rowLayout,
                        formItemProps,
                        formControlProps,
                        ...restColProps
                      } = child;
                      return (
                        <Col
                          xs={24}
                          sm={_perSpace}
                          key={`form-row-item-${index}`}
                          style={{ padding: "0px" }}
                          {..._layout}
                          {...restProps}
                          {...restColProps}
                          {...rowLayout}
                        >
                          <RenderFormItem {...child} />
                        </Col>
                      );
                    }
                  )}
                </Row>
              );
            }

            return (
              <RenderFormItem
                key={`${Symbol(item.id ?? "form-item-" + index).toString()}`}
                type={item.type}
                formItemProps={item.formItemProps}
                formControlProps={item.formControlProps}
              />
            );
        }
      })}
    </>
  );
};

export const RenderRowFormItems: React.FC<any> = (
  props: IFilterFormProps
): JSX.Element => {
  let {
    formItems,
    rowLayout = {},
    actionButtons: Component,
    actionButtonsPosition,
  } = props;

  let _colLen = 0;

  if (!actionButtonsPosition) {
    actionButtonsPosition = formItems?.length >= 3 ? "rowLine" : "inline";
  }

  return (
    <>
      <Row
        gutter={24}
        key={`${Symbol("filter-form-line").toString()}`}
        style={{ margin: "0px" }}
      >
        {formItems.map((item: IFormItemProps, index: number) => {
          if (Object.keys(item).length === 0) return;

          const { rules, ...restProps } = Object.create(
            item.formItemProps || {}
          );

          const _formItemLen =
            actionButtonsPosition === "inline"
              ? formItems?.length + 1
              : formItems?.length;

          _colLen = Math.ceil(
            24 / (props.itemsPerLine || (_formItemLen > 4 ? 4 : _formItemLen))
          );

          const _layout = {
            span: _colLen,
          };

          const { hidden = false, ...restItemProps } = item.formItemProps;

          if (item.children) {
            if (item.type === "group") {
              return (
                <Col
                  xs={24}
                  sm={_colLen}
                  key={`form-row-item-${index}`}
                  {..._layout}
                  {...restProps}
                >
                  <Form.Item
                    style={{ marginBottom: "0px" }}
                    key={`${Symbol(
                      item.id ?? "form-group-item-" + index
                    ).toString()}`}
                    hidden={typeof hidden === "boolean" ? hidden : false}
                    {...(restItemProps || {})}
                  >
                    {item.children.map(
                      (child: IFormItemProps, index: number) => {
                        const { component: Component } = child;

                        if (child.type === "splitline")
                          return (
                            <Component key={`inline-form-item-${index}`} />
                          );
                        return (
                          <RenderInlineFormItem
                            key={`inline-form-item-${index}`}
                            {...child}
                            index={index}
                          />
                        );
                      }
                    )}
                  </Form.Item>
                </Col>
              );
            }

            return (
              <Col
                xs={24}
                sm={_colLen}
                md={_colLen}
                key={`form-col-item-${index}`}
                {..._layout}
                {...restProps}
                style={{ padding: "0px" }}
              >
                <Row
                  gutter={24}
                  key={`${Symbol(item.id ?? "form-item-" + index).toString()}`}
                >
                  {item.children.map((child: IFormItemProps, index: number) => {
                    const { rules, ...restProps } = Object.create(
                      child.formItemProps
                    );

                    const _perSpace = Math.ceil(
                      24 /
                      (props.itemsPerLine ||
                        (item.children.length > 4 ? 4 : item.children.length))
                    );
                    const _layout = {
                      span: _perSpace,
                    };

                    child.formItemProps = {
                      ...child.formItemProps,
                    };

                    return (
                      <Col
                        xs={24}
                        sm={_perSpace}
                        key={`form-row-item-${index}`}
                        {...rowLayout}
                        {..._layout}
                        {...restProps}
                        style={{ padding: "0px" }}
                        {...(item.rowLayout || {})}
                      >
                        <RenderFormItem {...child} index={index} />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            );
          }

          return (
            <Col
              xs={24}
              sm={_colLen}
              md={_colLen}
              key={`form-col-item-${index}`}
              {...rowLayout}
              {..._layout}
              {...restProps}
              style={{ padding: "0px" }}
            >
              <RenderFormItem {...item} index={index} />
            </Col>
          );
        })}

        {actionButtonsPosition === "inline" && (
          <Col
            xs={24}
            sm={_colLen}
            md={_colLen}
            key={`form-row-item-action`}
            style={{ textAlign: "right" }}
          >
            <div className="ant-form-action-item">
              <Component />
            </div>
          </Col>
        )}
      </Row>

      {actionButtonsPosition != "inline" && (
        <Row
          gutter={24}
          key={`${Symbol("filter-form-line-action").toString()}`}
          {...rowLayout}
          style={{ textAlign: "right" }}
        >
          <Col xs={24} key={`form-row-item-action`}>
            <Component />
          </Col>
        </Row>
      )}
    </>
  );
};

export default RenderFormItems;
