import React, { useMemo, useState } from "react";
import { Steps, Button, Row, Col, Space, Empty } from "antd";
import MainForm from "../common";
// import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import "./index.scss";
import { IFormItemProps, IStepFormProps } from "../../types";

const { Step } = Steps;

let _formInstance = null;
let _totalStep = 0;

const StepForm: React.FC<IStepFormProps> = (props?: IStepFormProps): any => {
  const { stepItems = [], actionButtons } = props;

  const [CurrentStep, setStep] = useState<number>(0);
  const [FormData, setFormData] = useState<any>(props?.initValue || {});
  const [StepStatus, setStepStatus] = useState<boolean>(true);

  const ValidateForm = async () => {
    if (!_formInstance) return [];

    const _validation = await _formInstance?.validateFields().then((res) => {
      console.log(res, "formData");
      return res;
    });

    return _formInstance.getFieldsValue();
  };

  _totalStep = props.stepItems.length;

  const next = async () => {
    const _validateForm = await ValidateForm();
    const currentFormData = {
      ...FormData,
      ..._validateForm,
    };

    const updateInfo = () => {
      setFormData(currentFormData);
      setStep(CurrentStep + 1);
    };

    if (props.onNext && typeof props.onNext === "function") {
      props.onNext(CurrentStep, currentFormData, _formInstance, updateInfo);
    } else {
      updateInfo();
    }
  };

  const back = () => {
    history.go(-1);
  };

  const prev = () => {
    setStep(CurrentStep - 1);
  };

  const save = async () => {
    const _saveFormData = { ...FormData, ...(await ValidateForm()) };

    setFormData(_saveFormData);

    typeof props.onSave === "function" &&
      props?.onSave?.(_saveFormData, _formInstance);
  };

  const GetFormContent = (prop) => {
    console.log(prop.item, "--props");
    if (!prop.item) return <></>;

    if (prop.item?.component) {
      const SubComponent = prop.item.component;
      return typeof SubComponent === "function" ? (
        <SubComponent data={FormData} />
      ) : (
        SubComponent
      );
    }

    const _form = MainForm({
      ...prop.item.formProps,
      formItems: prop.item.formItems,
    });

    const { FormContent, Instance } = _form;

    _formInstance = Instance;

    // console.log(FormData,"___formdata")

    if (!!FormData) {
      _formInstance.setFieldsValue(FormData);
    }

    return (
      <>
        {prop?.item?.extraComponent?.()}
        <FormContent />
      </>
    );
  };

  const _actionButtons = useMemo(() => {
    if (!props.stepItems || props.stepItems?.length === 0) return <></>;
    return [
      <Button onClick={back} type="default" key="btn-back">
        返回
      </Button>,
      <Button
        onClick={prev}
        type="default"
        disabled={CurrentStep <= 0}
        key="btn-prev"
      >
        上一页
      </Button>,
      <Button
        onClick={next}
        type="primary"
        key="btn-next"
        style={{ display: CurrentStep === _totalStep - 1 ? "none" : "block" }}
      >
        下一页
      </Button>,
      <Button
        onClick={save}
        type="primary"
        key="btn-save"
        style={{ display: CurrentStep === _totalStep - 1 ? "block" : "none" }}
      >
        保存
      </Button>,
    ];
  }, []);

  return (
    <>
      {stepItems.length === 0 ? (
        <Empty description="该表单为空"></Empty>
      ) : (
        <Row>
          <Col xs={24} xl={12} style={{ margin: "auto" }}>
            <Space className="step-form" direction="vertical" size="large">
              <Steps current={CurrentStep}>
                {props.stepItems.map((item: any, index: number) => {
                  return (
                    <Step
                      key={`step-form-${index}`}
                      title={item.title}
                      description={item.description ?? ""}
                    />
                  );
                })}
              </Steps>

              <div className="steps-content">
                <div className="form-content">
                  <Row>
                    <Col
                      xs={24}
                      {...(props.stepItems[CurrentStep]?.descLayout ||
                        props.stepsLayout)}
                    >
                      <GetFormContent item={props.stepItems[CurrentStep]} />
                    </Col>
                  </Row>
                </div>
              </div>

              <div
                className="action-bar-content"
                style={{ textAlign: props.actionButtonPosition || "left" }}
              >
                <Space>{_actionButtons}</Space>
              </div>
            </Space>
          </Col>
        </Row>
      )}
    </>
  );
};

export default StepForm;
