import React from "react";
import { Result, Button } from "antd";
// import { useNavigate } from "react-router-dom";

const Page403 = () => {
  // const navigate = useNavigate();

  return (
    <Result
      status="403"
      title="403"
      subTitle="抱歉，您无权访问此页面。"
      extra={
        <Button
          type="primary"
          onClick={() => {
            history.go(-1)
          }}
          style={{ margin: "0px auto" }}
        >
          返回
        </Button>
      }
    />
  );
};
export default Page403;
