import { Button, Space, FormInstance,Spin } from "antd";
import React, { useEffect, useMemo, useImperativeHandle, useRef } from "react";

import { IFormProps } from "../../types";
import { FormComponent } from "../common";

const NormalFormDefault: IFormProps = {
  formItems: [],
  //   ref: null,
};

const NormalForm = React.forwardRef(
  (props: IFormProps, ref: any): JSX.Element => {
    props = { ...NormalFormDefault, ...props };

    const {
      formItems,
      data,
      actionButtons,
      showActionBar = true,
      onSave,
      onCancel,
      cancelButtonText = "取消",
      loading = false,
      ...restProps
    } = props;
    const formRef = useRef<FormInstance>({} as FormInstance);

    const saveForm = async () => {
      await formRef.current.validateFields();

      onSave?.(formRef.current.getFieldsValue());
    };

    const cancelForm = async () => {
      await formRef.current.resetFields();
      onCancel?.();
    };

    const ActionButtons = useMemo(() => {
      return showActionBar
        ? () => {
            let _buttons: Array<JSX.Element> = [
              <Button key={"save-button"} type="primary" htmlType={"submit"}>
                {props.saveButtonText || "保存"}
              </Button>,
              <Button
                key={"cancel-button"}
                className="danger"
                disabled={false}
                type="default"
                onClick={cancelForm}
              >
                {cancelButtonText}
              </Button>,
            ];
            if (actionButtons) {
              return <Space>{actionButtons(_buttons, formRef.current)}</Space>;
            }
            return <Space>{_buttons}</Space>;
          }
        : null;
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        ...formRef.current,
      }),
      []
    );

    useEffect(() => {
      if (data) {
        formRef.current.setFieldsValue(data);
      }
      return () => {
        formRef.current?.resetFields?.();
      };
    }, [data]);

    return (
      <Spin size="large" spinning={loading}>
        <FormComponent
          ref={formRef}
          formItems={formItems}
          actionButtons={ActionButtons}
          onFinish={saveForm}
          {...restProps}
        />
      </Spin>
    );
  }
);

export default NormalForm;
