import React, { useState } from 'react';
import { Popconfirm, PopconfirmProps } from "antd";

export type APopConfirmProps = Omit<PopconfirmProps, "onOpenChange" | "onConfirm"> & {
	onOpenChange?: ((open: boolean, e?: any) => void) | ((open: boolean, e?: any) => Promise<boolean | undefined>);
	onConfirm?: ((e?: React.MouseEvent<HTMLElement>) => void) | ((e?: React.MouseEvent<HTMLElement>) => Promise<boolean | undefined>);
	condition?: boolean;
};

const APopConfirm = (props: APopConfirmProps) => {
	const { condition = true, onConfirm, ...restProps } = props;

	const [isOpen, setOpen] = useState(false);


	const _onComfirm = async (evt?: any) => {
		// new Promise((resolve, reject) => {
		// 	reject("123")
		// })
		const _res = await onConfirm(evt);
		console.log(_res, 'res')
		if (!_res) {
			setOpen(true)
			return false;
		}

		setOpen(false)

		return true
	}

	const handleOpenChange = async (newOpen: boolean) => {
		console.log(newOpen, 'handle')

		// const _res = await onConfirm();


		if (!newOpen) {
			setOpen(newOpen)
			return;
		}

		if (condition) {
			setOpen(true)
		} else {
			setOpen(newOpen)
		}
	}


	return <Popconfirm open={isOpen}  {...restProps} onOpenChange={handleOpenChange} onConfirm={_onComfirm} onCancel={() => setOpen(false)}></Popconfirm>
};

export default APopConfirm;