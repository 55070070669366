import { Radio } from "antd";
import React from "react";

const FormRadio = (props) => {
	const { options, label, ...resCtrlProps } = props;

	if (!!options) {
		return <Radio.Group {...resCtrlProps} options={options}></Radio.Group>;
	}
	return <Radio {...resCtrlProps}>{label}</Radio>;
}

export default FormRadio;